.container {
    position: relative;
    min-height: 100vh;
    min-height: 100svh;

    .loadingBar {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 5px;
        border-top-left-radius: var(--card-border-radius);
        border-top-right-radius: var(--card-border-radius);
    }

    .document {
        position: relative;
        min-height: 100vh;
        min-height: 100svh;
        border-radius: var(--card-border-radius);
        transition: border-radius 0.2s ease-out;

        &.article {
            min-height: 100vh;
            min-height: 100svh;
        }

        &.smaller {
            min-height: calc(100vh - 60px);
            min-height: calc(100svh - 60px);
        }
    }

    .footer {
        position: relative;
        padding-bottom: var(--block-spacing);
        color: #fff;
        font-size: 1rem;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;

        .topAdContainer {
            display: flex;
            justify-content: center;
        }

        .topAd {
            margin: 0 auto;
            margin-bottom: var(--spacing-medium);
        }

        .content {
            --body-text-color: var(--article-text-color, var(--brand-text-color));
            --body-primary-color: var(--article-primary-color, var(--brand-primary-color));
            --header-color: var(--article-text-color, var(--brand-text-color));
            --header-primary-color: var(--article-primary-color, var(--brand-primary-color));

            position: relative;
            z-index: 1;
            display: none;
            padding-bottom: var(--spacing-medium);
            background: var(--article-background, var(--page-background));
            opacity: 0;
            transition: opacity 0.2s ease-in-out;

            .header {
                margin-bottom: var(--block-spacing);
            }
        }

        .metadata {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;

            .title {
                margin-top: 1em;
                font-size: 1.3em;
            }
        }

        .documentMetadata {
            --button-pill-color: var(--body-text-color);

            max-width: var(--block-max-width-text);
            padding: 0 var(--spacing);
            padding-bottom: var(--spacing-large);
            margin: 0 auto;
            color: var(--body-text-color);
            font-size: 0.85em;
        }

        &.hasContent {
            .metadata {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
            }

            .content {
                display: block;
            }
        }
    }

    .row {
        padding: var(--spacing-medium) 0;
        padding-top: var(--spacing-large);
        margin: 0;

        /* background: linear-gradient(180deg, rgba(58, 58, 58, 0.5) 0%, rgba(34, 34, 34, 0.57) 100%); */
        transition: padding 0.2s ease-out;

        .inner {
            padding: 0;
        }

        .title {
            padding: 0;
            padding-right: var(--side-spacing);
            padding-left: var(--side-spacing);
            margin: 0;
            margin-bottom: var(--spacing);
        }

        .summary {
            padding-top: 0;
        }

        /* .summaryButton {
            background: var(--color-white);
            color: var(--color-black);
        } */

        &.flush {
            padding: 0;

            .inner {
                padding: var(--spacing-medium) 0;
                padding-top: var(--spacing-large);
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    .locationCard {
        &:hover {
            background-color: var(--card-box-background-hover-dark);
        }
    }

    .locationIcon {
        color: var(--color-black);
    }

    .body {
        padding-bottom: var(--spacing-large);
    }

    &.isLoading {
        border-radius: var(--card-border-radius);
        background-color: rgb(255 255 255 / 0.1);
    }

    &.entered {
        /* .metadata,
        .suggestions {
            padding: 0 var(--side-spacing);
        } */

        .document {
            min-height: 100vh;
            min-height: 100svh;
            border-radius: 0;
        }

        &.isFullscreen {
            .footer {
                display: none;
            }
        }
    }

    &.withoutFooter,
    [data-theme='reader'] & {
        .footer {
            display: none;
        }
    }

    &.footerContentVisible {
        .footer {
            &.hasContent {
                .metadata {
                    opacity: 0;
                    pointer-events: none;
                }

                .content {
                    opacity: 1;
                }
            }
        }
    }

    &.current {
        .footer {
            opacity: 1;
        }
    }


    &.themeSaqQuizGenz {
        .header {
            color: var(--color-white);
        }
    }

    @media (--medium-viewport) {
        .footer {
            .metadata {
                .title {
                    font-size: 1.5em;
                }
            }
        }
    }
}
