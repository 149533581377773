.container {
    font-size: 1rem;

    .inner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: calc(-1 * var(--block-spacing));

        > * {
            padding-right: var(--spacing);
            padding-bottom: var(--block-spacing);
            margin-right: auto;

            &:last-child {
                padding-right: 0;
                margin-right: 0;
            }
        }
    }
}
