.container {
    --block-spacing: 40px;

    padding: var(--side-spacing) 0;
    color: #fff;

    .header {
        display: flex;
        flex-direction: column;
        padding: 0 var(--side-spacing);
        margin-bottom: var(--block-spacing);
    }

    .player {
        position: relative;
        display: flex;
        width: 100%;
        /* height: 75vh;
        height: 75svh; */
        margin-bottom: var(--block-spacing);

        .video,
        .frame {
            position: relative;
            overflow: hidden;
            border-radius: 20px;
            margin: auto;
            background: #000;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
        }
    }

    .presentation {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;

        .slogan {
            width: 100%;
            max-width: 200px;
            margin-bottom: var(--block-spacing);
        }

        .description {
            max-width: 30em;
            font-size: 1.25rem;
            line-height: 1.2;

            p {
                margin: 0;
                margin-bottom: 1em;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .section {
        margin-bottom: var(--block-spacing);

        .title {
            padding: 0 var(--side-spacing);
            margin-bottom: var(--spacing);
            text-transform: none;
            font-size: 1.5rem;
        }
    }

    .items {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        padding: 0 var(--side-spacing);
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        overflow-x: auto;
        scrollbar-width: none;
        scroll-snap-type: x mandatory;
    }

    .item {
        overflow: hidden;
        min-width: 300px;
        border-radius: 10px;
        margin-right: var(--row-spacing);
        scroll-margin-left: 0;
        scroll-snap-align: center;

        &:last-child {
            margin-right: 0;
        }
    }

    .card {
        --card-thumbnail-border-radius: var(--card-border-radius);

        padding-bottom: 10px;
        border-bottom: solid 1px rgba(255 255 255 / 0.2);
    }

    &.withLastMicromag {
        .player {
            width: 100%;
        }
        .frame {
            width: 100%;
            max-width: 450px;
        }
    }

    @media (--small-viewport) {
        .item {
            scroll-margin-left: var(--side-spacing);
            scroll-snap-align: start;
        }
    }

    @media (--medium-viewport) {
        --block-spacing: 60px;

        .header {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .card {
            --card-thumbnail-width: 100%;
            --card-label-margin: 10px var(--card-border-radius);
            --card-font-size: 1.25em;
            --card-spacing-surtitle: 5px;
            --card-spacing-title: 10px;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .player {
            min-height: 75vh;
            min-height: 75svh;
            max-height: 75vh;
            flex-grow: 1;
            margin-right: var(--side-spacing);
            margin-bottom: 0;
        }

        .presentation {
            min-width: 300px;
            max-width: 50%;
            flex-grow: 1;

            .slogan {
                max-width: 300px;
                margin-bottom: var(--spacing-large);
            }
        }

        &.withLastMicromag {
            .player {
                max-height: none;
            }
        }
    }

    @media (--large-viewport) {
        --block-spacing: 80px;

        .presentation {
            /* width: 50%;
            min-width: 400px; */

            .slogan {
                max-width: 400px;
            }

            .description {
                font-size: 1.5rem;
            }
        }
    }
}
