.container {
    .video {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        transition: opacity 0.2s ease-out;
    }

    &.urbania {
        background: #333;
    }

    &.dehors {
        background: #c8dfff;
    }

    &.quatre95 {
        background: var(--color-quatre95);
    }

    &.mollo {
        background: var(--color-mollo);
    }

    &.periodelibre {
        background: var(--color-periodelibre);
    }

    &.france {
        background: var(--color-france);
    }

    &.loaded {
        .video {
            opacity: 1;
        }
    }
}
