/* stylelint-disable selector-class-pattern */
:global {
    :local(.container) {
        .block-image,
        .block-video,
        .block-embed,
        .block-ad,
        .block-animation {
            display: block;
            max-width: var(--block-max-width-visual);
            height: auto;
            padding: 0 var(--spacing);
            margin: 0 auto;
            margin-bottom: 1em;

            &[data-presentation='small'] {
                max-width: var(--block-max-width-small);
            }

            &[data-presentation='medium'] {
                max-width: var(--block-max-width-text);
            }

            &[data-presentation='full'] {
                max-width: none;
                padding: 0;
            }
        }

        .block-animation {
            overflow: hidden;
            /* max-width: var(--block-max-width-text);

            &[data-presentation='small'] {
                max-width: var(--block-max-width-small);
            }

            &[data-presentation='medium'] {
                max-width: var(--block-max-width-text);
            }

            &[data-presentation='full'] {
                max-width: none;
            } */
        }

        .block-images {
            display: block;
            padding: 0 var(--side-spacing);
            margin: 0 auto;
            margin-bottom: 1em;
        }

        .block-ad,
        .block-embed {
            display: flex;
            overflow: hidden;
            width: 100%;
            max-width: none;
            padding: 0;
            margin: 0 auto;

            > :first-child {
                width: 100%;
                margin: 0 auto;
            }

            iframe {
                max-width: var(--block-max-width-visual);
                margin: 0 auto;
            }

            .iframe-urbania_legacy {
                width: 100%;
                max-width: none;
                height: 80vh;
            }

            .tiktok-embed {
                width: 325px !important;
            }
        }

        .block-ad {
            > :first-child {
                width: auto;
            }
        }
    }
}
