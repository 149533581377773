.container {
    --z-index-background: 0;
    --z-index-logo: 1;
    --z-index-navigation: 2;
    --z-index-neighbor-pages: 3;
    --z-index-current-page: 4;
    --z-index-navigation-over: 5;
    --z-index-sidebar: 6;
    --z-index-bumper: 7;

    position: relative;
    /* overflow: hidden; */
    min-height: 100vh;
    /* background-attachment: fixed; */
    background-color: var(--color-black);
    /* background-position: top left;
    background-repeat: repeat;
    transition: background-image 0.2s ease-out; */

    .bumperAd {
        position: fixed;
        z-index: var(--z-index-bumper);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        contain: size layout paint style;
    }

    &::before {
        position: fixed;
        z-index: var(--z-index-background);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-attachment: fixed;
        background-color: var(--color-black);
        background-position: top left;
        background-repeat: repeat;
        content: '';
        transition: background-image 0.2s ease-out;
    }

    .header {
        position: fixed;
        z-index: var(--z-index-logo);
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        contain: content;
    }

    .brandLogo {
        display: block;
        width: auto;
        height: 60px;
        margin: auto;
        color: var(--color-white);
    }

    .sidebar {
        position: fixed;
        z-index: var(--z-index-sidebar);
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: var(--color-white);
        contain: size layout paint style;
    }

    .menu {
        --side-spacing: var(--side-spacing-menu);

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .menuButtons {
        position: fixed;
        z-index: var(--z-index-navigation);
        top: 0;
        right: 0;
        display: flex;
        contain: content;
        transition: opacity 0.2s ease-out;

        .accountButton {
            display: block;
            width: calc(var(--menu-button-size) + 5px + 5px);
            height: calc(var(--menu-button-size) + var(--spacing) + 5px);
            padding: var(--spacing) 5px 5px;
            color: var(--color-white);
        }

        .menuButton {
            display: block;
            width: calc(var(--menu-button-size) + 5px + var(--spacing));
            height: calc(var(--menu-button-size) + var(--spacing) + 5px);
            padding: var(--spacing) var(--spacing) 5px 5px;
            color: var(--color-white);
        }
    }

    .contentCloseButton {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        border: 0;
        appearance: none;
        background: transparent;
        pointer-events: all;
    }

    .content {
        position: relative;
        /* overflow: hidden; */
        width: 100%;
        min-height: 100vh;
        min-height: 100svh;
        touch-action: pan-y;
    }

    .pageContainer {
        position: absolute;
        z-index: var(--z-index-neighbor-pages);
        top: 0;
        overflow: hidden;
        width: 100%;
        min-height: 100vh;
        min-height: 100svh;
        contain: style;
        transform-origin: 50% 50svh;

        .pageLink {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .page {
            z-index: 1;
            overscroll-behavior: contain;
        }

        .pageSize {
            position: relative;
            min-height: 100vh;
        }

        .pageContent {
            overflow: hidden;
            border-radius: var(--card-border-radius);
        }

        .placeholder {
            position: relative;
            min-height: 100vh;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: pulse;
            animation-play-state: running;
            animation-timing-function: ease-in-out;
            background: rgba(100 100 100 / 0.6);
        }

        &.current {
            position: relative;
            z-index: var(--z-index-current-page);
            top: 0;
            left: 0;
            max-height: none;

            .pageContent {
                box-shadow: 0 0 50px 0 #000;
            }

            .pageLink {
                display: none;
            }
        }

        &.neighbor {
            /* display: none; */
            position: fixed;
            overflow: hidden;
            max-height: 100vh;
            max-height: 100svh;
            contain: size layout paint style;
            cursor: pointer;

            .page {
                pointer-events: none;
                user-select: none;

                :global(.block) {
                    content-visibility: auto;
                }
            }
        }

        &.transitioning {
            position: absolute;
            overflow: hidden;
            max-height: 100vh;
            max-height: 100svh;
            contain: size layout paint style;
        }

        &.entered {
            overflow: visible;

            .pageContent {
                overflow: visible;
                border-radius: 0;
                box-shadow: none;
            }
        }

        &.entering {
            overflow: hidden;

            .pageContent {
                overflow: hidden;
                border-radius: 0;
            }
        }

        &.leaving {
            overflow: hidden;

            .pageContent {
                overflow: hidden;
                border-radius: var(--card-border-radius);
                box-shadow: 0 0 50px 0 #000;
            }
        }

        &.inactive {
            pointer-events: none;
            user-select: none;
        }

        &.isEmpty {
            .pageSize {
                background: rgba(255 255 255 / 0.5);
            }
        }
    }

    &.menuOpened {
        .sidebar {
            box-shadow: 0 0 5px 2px rgb(0 0 0 / 0.15);
        }
    }

    &.sidebarOverContent {
        &.menuOpened {
            .content > * {
                pointer-events: none;
                user-select: none;
            }

            .contentCloseButton {
                z-index: var(--z-index-navigation-over);
                display: block;
                pointer-events: all;
            }
        }
    }

    &.zoomedIn {
        .menuButtons {
            opacity: 0;
        }
    }

    &.menuButtonOverContent {
        .menuButtons {
            z-index: var(--z-index-navigation-over);
            opacity: 1;
        }

        &.zoomedIn {
            .menuButtons {
                opacity: 1;
            }
        }
    }

    &.urbania {
        &::before {
            background-image: url('../../assets/img/brands/urbania_tile.png');
            background-size: 200px 200px;
        }
    }

    &.gdu {
        &::before {
            background-image: url('../../assets/img/brands/gdu_tile.png');
            background-size: 150px 112px;
        }
    }

    &.dehors {
        &::before {
            background-image: url('../../assets/img/brands/dehors_tile.png');
            background-size: 250px 250px;
        }
    }

    &.quatre95 {
        &::before {
            background-image: url('../../assets/img/brands/quatre95_tile.png');
            background-size: 200px 200px;
        }
    }

    &.mollo {
        &::before {
            background-image: url('../../assets/img/brands/mollo_tile.png');
            background-size: 200px 200px;
        }
    }

    &.isTransitioning {
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100dvh;
        height: 100vh;
        contain: size layout paint style;

        .content {
            overflow: hidden;
        }
    }

    @media (--small-viewport) {
        .sidebar {
            width: 400px;
        }
    }

    @media (--medium-viewport) {
        --side-spacing: var(--side-spacing-medium);
    }
}

.modalsOpened {
    position: fixed;
    z-index: var(--modals-z-index);
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    contain: size layout paint style;
}

.messages {
}

@keyframes pulse {
    0% {
        background: rgba(100 100 100 / 0.6);
    }

    50% {
        background: rgba(100 100 100 / 0.5);
    }

    100% {
        background: rgba(100 100 100 / 0.6);
    }
}
