.container {
    margin: 0;
}

.shape {
    position: relative;
    width: 100%;
    height: 0;
}

.image {
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; */
    position: relative;
    width: 100%;
    height: auto;

    .picture {
        position: relative;
        width: 100%;
        height: auto;
        max-height: 100vh;
        object-fit: contain;
    }
}

.caption {
    margin-top: 10px;
    font-size: 0.85em;
}
